.card {
    overflow: hidden;
    position: relative;
    text-decoration: none;
    color: black;
    border-color: rgb(28 25 23);
    border-style: solid;
    border-width: 2px;
    border-radius: 0.375rem;
    text-align: left;
    transition: 0.2s ease-in-out;
    width: 21rem;
    cursor: grab;
}

.card:active {
    cursor: grabbing;
}

.dark-mode .card {
    color: white;
    border-color: white;
}

.card__content {
    height: 12rem;
}

.card__img {
    object-fit: cover;
    height: auto;
    width: 100%;
    top: 0;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    user-select: none;
}

.card__content:hover>.card__img {
    opacity: 0;
    width: 0;
}

.card__info {
    box-sizing: border-box;
    padding: 1rem;
    padding-top: 0.5rem;
    width: 100%;
}

.card__info--title {
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin: 0.5rem 0;
    user-select: none;
    font-weight: 600;
}

.card__info--tech {
    display: flex;
    gap: 0.5rem;
    font-size: .875rem;
    line-height: 1.25rem;
    justify-content: flex-start;
    align-items: center;
}

.card__info--tag {
    font-weight: 600;
    padding: 0.25rem 0.5rem;
    border-color: rgb(28 25 23);
    border-width: 2px;
    border-radius: 0.375rem;
    border-style: solid;
    user-select: none;
}

.dark-mode .card__info--tag {
    border-color: white;
}

.card__links {
    height: 12rem;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    box-sizing: border-box;
    gap: 1rem;
    font-size: 14px;

    opacity: 0;
    height: 0;
    transition: opacity 0.2s ease-in-out;
    padding: 0rem;
}

.card__content:hover>.card__links {
    opacity: 1 !important;
    height: 12rem;
    padding: 2rem;
}

.card__content:hover>.card__links .button {
    display: block;
}

.card__links .button {
    display: none;
    width: 12rem;
    text-decoration: none;
    padding: 0.5rem;
    color: black;
    text-align: center;
    transition: all 0.4s ease-in-out;
}

.card__links .button:hover,
.card__links .button:focus {
    box-shadow: inset 0 0 0 2em #000;
    color: #fff;
}

.dark-mode .card__links .button {
    color: #fff;
}

.dark-mode .card__links .button:hover,
.dark-mode .card__links .button:focus {
    box-shadow: inset 0 0 0 2em #fff;
    color: #000;
}


.card[wobble='1'] {
    -webkit-animation: shake 0.8s;
    animation: shake 0.8s;
}

@keyframes shake {

    30%,
    50%,
    70% {
        transform: translate3d(-2px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(2px, 0, 0);
    }
}